<template>
  <div class="">
    <div class="px-3">
      <label for="content" class="editorStyleTitle">Text:</label>
      <RichTextArea v-model="block.content" :questionList="getAllQuestionsList" :inputs="getCustomInputs"></RichTextArea>
    </div>
    <div>
      <hr>
      <div class="px-3">
  <FontFamily  v-model="block.style.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
<input v-if="!isDesktopView" type="number" v-model="block.style.mobileFontSize" placeholder="Font size Mobile" class="editor-input-control w-100 input-bg-color border-0">
<input  type="number" v-else v-model="block.style.fontSize" placeholder="Font size " class="editor-input-control w-100 input-bg-color border-0">

</div>
    <FontStyles :textBold.sync="block.style.fontWeight" 
  :textItalic.sync=" block.style.fontStyle"
  :textUnderLine.sync="block.style.textDecoration" class=""></FontStyles>
   
  </div>
  <div class="d-flex gap">
    <TextAlign v-model=block.style.textAlign ></TextAlign>
    <div class="" title="Width (px)" style="gap:4px">
<label class="editorStyleTitle"  >Width:</label>
  <input type="number" v-model="block.style.width"  placeholder="Width" class="editor-input-control w-100 input-bg-color border-0">
</div>
  </div>

 </div>
 <hr>
 <div class="px-3">
  <div class="d-flex gap">
<div class="d-flex align-items-center" title="Line Height" style="gap:4px">
<span class="" style="color: #B3AFB6;" ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5.83332H7.08333L4.16667 2.91666L1.25 5.83332H3.33333V14.1667H1.25L4.16667 17.0833L7.08333 14.1667H5V5.83332ZM8.33333 4.16666V5.83332H18.3333V4.16666H8.33333ZM8.33333 15.8333H18.3333V14.1667H8.33333V15.8333ZM8.33333 10.8333H18.3333V9.16666H8.33333V10.8333Z" fill="currentColor"></path></svg></span>
  <input type="number" v-model="block.style.lineHeight"  placeholder="Line Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center" title="Rotate" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.925 7.10834L4.75 5.92501C4 6.89168 3.53333 8.00834 3.39166 9.16668H5.075C5.19166 8.44168 5.48333 7.73334 5.925 7.10834ZM5.075 10.8333H3.39166C3.53333 11.9917 3.99166 13.1083 4.74166 14.075L5.91666 12.8917C5.48333 12.2667 5.19166 11.5667 5.075 10.8333ZM5.91666 15.2667C6.88333 16.0167 8.00833 16.4667 9.16666 16.6083V14.9167C8.44166 14.7917 7.74166 14.5083 7.11666 14.0583L5.91666 15.2667ZM10.8333 3.39168V0.833344L7.04166 4.62501L10.8333 8.33334V5.07501C13.2 5.47501 15 7.52501 15 10C15 12.475 13.2 14.525 10.8333 14.925V16.6083C14.125 16.2 16.6667 13.4 16.6667 10C16.6667 6.60001 14.125 3.80001 10.8333 3.39168Z" fill="currentColor"></path></svg></span>
  <input type="number"  v-model="block.style.rotate" placeholder="Rotate" class="editor-input-control w-100 input-bg-color border-0">
</div>
  </div>
 </div>
 <hr>
 <div class="px-3">
  <SelectColor v-model="block.style.color"  :opacity.sync="block.style.textOpacity" class="mt-3" :label="'Text Color'"></SelectColor>
 </div>
 <hr>
 <div class="px-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.marginRight" :left.sync="block.style.marginLeft" :top.sync="block.style.marginTop" :bottom.sync="block.style.marginBottom"></padding>
  <padding  v-else :label="'Spacing (Mobile)'"  :right.sync="block.style.mobileMarginRight" :left.sync="block.style.mobileMarginLeft" :top.sync="block.style.mobileMarginTop" :bottom.sync="block.style.mobileMarginBottom"></padding> 
</div>
      
    </div>
  </div>
</template>
<script>
import RichTextArea from "../MiniComponents/RichTextArea.vue";
import fontFamilyList from "../../../staticPages/fontFamilyList";

import { mapGetters } from "vuex";
import FontFamily from "../MiniComponents/FontFamily.vue";
import TextAlign from "../MiniComponents/TextAlign.vue";
import FontStyles from "../MiniComponents/FontStyles.vue";
import SelectColor from "../MiniComponents/SelectColor.vue";
import Padding from "../MiniComponents/Padding.vue";
export default {
  components: {Padding,RichTextArea,FontFamily,TextAlign,FontStyles,SelectColor},
  props: {
    block: Object,
    index: Number,
    QuestionTitleExist: Boolean,
    isDesktopView: Boolean,
    isResultPage: Boolean,
  },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }],
      ],
    };

    //   this.contentLocal = this.content;
  },
  methods: {
    isPropertyDisabled(property) {
      if (this.block.content.toLowerCase().includes(property.toLowerCase())) {
        return true;
      }
      return false;
    },
    addProperty(property) {

      
      const textInput = this.$refs.textContentInputRef;
      const startPos = textInput.selectionStart;
      const endPos = textInput.selectionEnd;
      const textBefore = this.block.content.substring(0, startPos);
      const textAfter = this.block.content.substring(endPos, this.block.content.length);
      this.block.content = textBefore + " " + property +  " " + textAfter;
      
    },
  },
  computed: {
    ...mapGetters(["getUserCustomLeadInputFields" , "getAllQuestions"]),
    getCustomInputs() {
      return this.getUserCustomLeadInputFields;
    },
    getAllQuestionsList(){
      return this.getAllQuestions
    },
    textBlockFont(){
      return this.block.style.fontFamily
    },
    getFontFamilyList() {
return fontFamilyList;
}
  },
  watch:{
    textBlockFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    }
  }
};
</script>
<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.PropertyDropdown :deep(.dropdown-toggle) {
  background: none;
  color: #000;
}
.PropertyDropdown :deep(.dropdown-menu) {
  max-height: 400px;
  overflow: hidden scroll;
}
.PropertyDropdown :deep(button) {
  border: 0.5px solid #dfdfdf !important;
}
.PropertyDropdown :deep(button):focus {
  outline: none !important;
}
.PropertyDropdown :deep(.dropdown-item:active) {
  color: #212529;
  background-color: transparent;
}

.labelDiv {
  padding: 0.75rem 0.75rem 0.5rem;
  border-top: 1px solid #c4c9cf;
}

.labelDiv span {
  font-size: 0.75rem;
  color: #4b4d4e;
}
</style>
